<template>
    <app-guide-inline
        v-if="store.selectableActions.length"
        :closable="true"
        text="I’ve put together a plan for you to put these activities into action. Feel free to adjust this to suit your own learning style!"
    />
    <app-guide-chat-activator />
    <app-guide-chat-dialog :screen="GuideSupportedScreen.ChallengePath" />
</template>

<script setup lang="ts">
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { GuideSupportedScreen } from '@/store/guide/types';

    const store = useSingleChallengePathStore();
</script>
