<template>
    <v-carousel
        v-model="currentIndex"
        :show-arrows="true"
        :hide-delimiters="true"
        v-if="store.carouselItems.length"
        :height="427"
        :class="classes"
    >
        <v-carousel-item v-for="(item, i) in store.carouselItems" :key="item.id" cover>
            <PersonalValueCard
                :value="item"
                class="fill-height"
                :width="mainCardWidth"
                :removable="props.removable"
                @removed="$emit('removed:main-card', item)"
            />
        </v-carousel-item>
        <template v-slot:prev="{ props }">
            <PersonalValueCard
                v-if="prevCard"
                :value="prevCard"
                :width="iconCardWidth"
                class="rounded-xl fill-height"
                @click="props.onClick"
                :main="false"
            />
            <span v-else></span>
        </template>
        <template v-slot:next="{ props }">
            <PersonalValueCard
                v-if="nextCard"
                :value="nextCard"
                :width="iconCardWidth"
                class="rounded-xl fill-height"
                @click="props.onClick"
                :main="false"
            />
            <span v-else></span>
        </template>
    </v-carousel>
    <v-row class="pa-0 pt-6 pb-2 w-100 mt-n12" no-gutters align="center">
        <v-col :cols="isDesktop ? 3 : 2" class="pa-0 text-right">
            <v-btn
                v-if="prevCard !== null"
                class="rounded-4"
                variant="flat"
                color="custom-yellow"
                icon="mdi-arrow-left"
                @click="() => (prevCard !== null ? (currentIndex = currentIndex - 1) : null)"
            />
        </v-col>
        <v-col class="justify-center">
            <PersonalValueSubTypeButtons />
        </v-col>
        <v-col :cols="isDesktop ? 3 : 2" class="pa-0 text-left">
            <v-btn
                v-if="nextCard !== null"
                class="rounded-4"
                variant="flat"
                color="custom-yellow"
                :style="{ 'border-radius': '4px' }"
                icon="mdi-arrow-right"
                @click="() => (nextCard !== null ? (currentIndex = currentIndex + 1) : null)"
            ></v-btn>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import PersonalValueCard from '@/components/canvas/personal-values/upload/PersonalValueCard.vue';
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { storeToRefs } from 'pinia';
    import PersonalValueSubTypeButtons from '@/components/canvas/personal-values/upload/second-step/PersonalValueSubTypeButtons.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const store = usePersonalValuesStore();

    const props = defineProps<{
        removable?: boolean;
    }>();

    const { isDesktop } = useIsDesktop();

    // Bind store state to v-model
    // https://stackoverflow.com/questions/76370713/how-to-use-pinia-store-getters-on-v-model-of-an-input
    const { currentIndex } = storeToRefs(store);

    const { name } = useDisplay();
    const classes = computed(() => {
        const next = nextCard.value ? 'has-next ' : 'no-has-next';
        const prev = prevCard.value ? 'has-prev' : 'no-has-prev';
        return `personal-values-carousel ${next} ${prev} ${name.value}`;
    });

    const nextCard = computed(() => {
        if (currentIndex.value < store.carouselItems.length) {
            return store.carouselItems[currentIndex.value + 1];
        } else {
            return null;
        }
    });

    const prevCard = computed(() => {
        if (store.carouselItems.length > 0 && currentIndex.value > 0) {
            return store.carouselItems[currentIndex.value - 1];
        } else {
            return null;
        }
    });

    const mainCardWidth = computed(() => {
        switch (name.value) {
            case 'xs':
                return 300;
            case 'sm':
            case 'md':
                return 440;
            case 'lg':
            case 'xl':
            case 'xxl':
                return 360;
            default:
                return 300;
        }
    });

    /**
        Note: this value is coupled to the css styles in the style block
    */
    const iconCardWidth = computed(() => {
        return '50%';
    });
</script>
<style lang="scss" scoped>
    // Common styles for .v-window-item and .v-window__controls across all breakpoints
    :deep(.v-window-item) {
        align-self: center;
    }

    // Transition overrides
    .v-window-x-transition-enter-active,
    .v-window-x-transition-leave-active,
    .v-window-x-reverse-transition-enter-active,
    .v-window-x-reverse-transition-leave-active,
    .v-window-y-transition-enter-active,
    .v-window-y-transition-leave-active,
    .v-window-y-reverse-transition-enter-active,
    .v-window-y-reverse-transition-leave-active {
        transition: 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    .personal-values-carousel {
        --main-width: v-bind(mainCardWidth);

        :deep(.v-window__controls) {
            // This container has position absolute on top of the main card.
            // The intention is to keep the gap between the previous and next card using the
            // main card width, and using the left absolute position to keep the cards centered.
            left: calc((var(--main-width)) / -2 * 1px) !important;
            gap: calc((var(--main-width)) * 1px) !important;
            .personal-values-card {
                transform: scale(0.9);
            }

            .personal-values-card:first-child .v-overlay {
                // TODO: gradient to try to making whole content diffuse is not working
                background: linear-gradient(
                    to left,
                    white 0%,
                    white 50%,
                    white 100%
                );
                opacity: 1;
            }

            .personal-values-card:last-child {
                // TODO: gradient to try to making whole content diffuse is not working
                background: linear-gradient(
                    to left,
                    white 0%,
                    /* Diffuse white on the left */ white 50%,
                    /* Fully white from the center */ white 100% /* Stays white at the right edge */
                );
                opacity: 1;
            }
        }
    }
</style>
