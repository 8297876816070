<template>
    <v-chip
        class="text-white text-caption text-capitalize font-weight-600 app-label"
        :class="`app-type-label-${mappedType.toLowerCase()}`"
        size="23"
        label
    >
        {{ mappedType }}
    </v-chip>
</template>

<script setup lang="ts">
    import { PlanActionItemType } from '@/store/plan/common/types';
    import { computed } from 'vue';
    import { SkillType } from '@/store/skills/types';

    const props = defineProps<{
        type: PlanActionItemType | SkillType;
    }>();

    const mappedType = computed(() => {
        switch (props.type) {
            case PlanActionItemType.ConnectActionType:
                return 'connections';
            case PlanActionItemType.ExperienceActionType:
                return 'experiences';
            case PlanActionItemType.NewSkillActionType:
                return 'skills';
            case SkillType.Learned:
                return 'learned';
            case SkillType.Technical:
                return 'technical';
            case SkillType.Personal:
                return 'personal';
            default:
                return 'type';
        }
    });
</script>

<style scoped lang="scss">
    .app-label {
        padding: 4px 8px;
    }

    .app-type-label-experience,
    .app-type-label-experiences,
    .app-type-label-learned {
        background-color: rgb(var(--v-theme-secondary-blue));
    }

    .app-type-label-connection,
    .app-type-label-connections {
        background-color: rgb(var(--v-theme-secondary-pink));
    }

    .app-type-label-technical {
        background-color: rgb(var(--v-theme-secondary-red));
    }

    .app-type-label-skill,
    .app-type-label-skills,
    .app-type-label-personal {
        background-color: rgb(var(--v-theme-secondary-orange));
    }
</style>
