<template>
    <v-card class="error-screen" v-if="appError.hasError" color="primary">
        <Logo width="200" class="logo" />
        <v-card-text class="h4 w-100">
            <div>
                <v-icon size="40" icon="">mdi-alert-circle</v-icon>
                Unexpected application error. Please <a href="/" target="_blank">reload</a> the page.
            </div>
            <br />
            <div class="mt-16 text-caption">
                If the error persists, please <a href="mailto:support@actvo.ai">contact</a> support
                for assistance.
            </div>
        </v-card-text>
    </v-card>
    <slot v-else></slot>
</template>

<script setup lang="ts">
    import { useAppErrorStore } from '@/store/appErrorStore';
    import Logo from '@/components/header/Logo.vue';
    // import { onErrorCaptured } from 'vue';

    const appError = useAppErrorStore();

    // onErrorCaptured((error) => {
    //     appError.catchErrors(error);
    // });
</script>

<style scoped>
    .error-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        padding: 40px;
        text-align: center;

        .logo {
            position: absolute;
            left: 40px;
            top: 40px;
        }
    }
</style>
