// Customer error classes, which may be used througout the application.


/*
 * A base class for app errors.
 */
class BaseError extends Error {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, BaseError.prototype);
    }
}

/*
 * A base class for API errors.
 */
export class ApiError extends BaseError {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}


/*
 * A class to represent an error in the API authentication process.
 */
export class ApiAuthError extends ApiError {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiAuthError.prototype);
    }
}

/*
 * A class to represent an error in the Auth0 authentication process.
 */
export class Auth0Error extends BaseError {
    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, Auth0Error.prototype);
    }
}
