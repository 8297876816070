import { create, createInBackground, remove, update } from '@/services/base';
import type { ApiResponse } from '@/services/apiClient';
import {
    type CurrentChallengeActionFeedbackCreateData,
    deletePlanCurrentChallengeAction,
    postChallengePathActionsNewSuggestions,
    postChallengePathActionsSuggestions,
    postPlanChallengeActionFeedback,
    postPlanCurrentChallengeActions,
    putPlanCurrentChallengeAction,
} from '@/services/plan/current/actions/api';
import type {
    CurrentChallengeAction,
    CurrentChallengeActionCreateData,
    CurrentChallengeActionUpdateData,
} from '@/api/types/plan/currentChallengeAction';
import type { EditableCurrentChallengeActionFeedback } from '@/store/challenge-path/feedback/util';
import type { CurrentChallengeActionFeedback } from '@/api/types/plan/currentChallengeActionFeedback';

export async function updateCanvasCurrentChallengeAction(
    canvasId: number,
    challengeId: number,
    actionId: number,
    updateData: CurrentChallengeActionUpdateData,
    accessToken: string,
): Promise<void> {
    return update(
        'canvas-current-challenge-action',
        async () =>
            await putPlanCurrentChallengeAction(
                canvasId,
                challengeId,
                actionId,
                updateData,
                accessToken,
            ),
    );
}

export async function createPathwayChallengeSuggestions(
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    return createInBackground(
        'create-pathway-challenge-suggestions',
        async () => await postChallengePathActionsSuggestions(canvasId, challengeId, accessToken),
    );
}

export async function createPathwayChallengeNewSuggestions(
    canvasId: number,
    challengeId: number,
    accessToken: string,
): Promise<ApiResponse<void>> {
    return createInBackground(
        'create-pathway-challenge-new-suggestions',
        async () => await postChallengePathActionsNewSuggestions(canvasId, challengeId, accessToken),
    );
}

export async function createCanvasCurrentChallengeAction(
    canvasId: number,
    challengeId: number,
    data: CurrentChallengeActionCreateData,
    accessToken: string,
): Promise<[CurrentChallengeAction, ApiResponse<CurrentChallengeAction>]> {
    return await create(
        'canvas-current-challenge-action',
        async () => await postPlanCurrentChallengeActions(canvasId, challengeId, data, accessToken),
    );
}

export async function removeCanvasCurrentChallengeAction(
    canvasId: number,
    challengeId: number,
    actionId: number,
    accessToken: string,
): Promise<any> {
    return remove(
        'canvas-current-challenge-action',
        async () =>
            await deletePlanCurrentChallengeAction(canvasId, challengeId, actionId, accessToken),
    );
}

export async function createCanvasCurrentChallengeActionFeedback(
    canvasId: number,
    challengeId: number,
    data: EditableCurrentChallengeActionFeedback,
    accessToken: string,
): Promise<[CurrentChallengeActionFeedback, ApiResponse<CurrentChallengeActionFeedback>]> {

    const payload: CurrentChallengeActionFeedbackCreateData = {
        rating: data.rating,
        helpful: data.helpful,
        description: data.description ?? null,
        add_to_learned_experience: data.add_to_learned_experience,
    }

    return await create(
        'canvas-current-challenge-action-feedback',
        async () =>
            await postPlanChallengeActionFeedback(
                canvasId,
                challengeId,
                data.action.id,
                payload,
                accessToken,
            ),
    );
}
