import type { ActionFrequency } from '@/api/types/plan/currentChallengeAction';

export interface ApiFrequencyOption {
    frequency: ActionFrequency;
    duration_min: number;
    duration_max?: number;
}

export interface FrequencyOption {
    value: number;
    title: string;
    key: ActionFrequency | string;
}

// Define the available options
export const API_FREQUENCY_OPTIONS: ApiFrequencyOption[] = [
    // { value: 1, title: '15 minutes a day', frequency: 'daily', duration_min: 15 },
    // { value: 2, title: '15-30 minutes a day', frequency: 'daily', duration_min: 15, duration_max: 30 },
    // { value: 3, title: '1 hour a day', frequency: 'daily', duration_min: 60 },
    // { value: 11, title: 'once a week', frequency: 'weekly', duration_min: 1 },
    // { value: 12, title: '2-3 times a week', frequency: 'weekly', duration_min: 2, duration_max: 3 },
    // { value: 13, title: '3-5 times a week', frequency: 'weekly', duration_min: 3, duration_max: 5 },
    // { value: 14, title: 'every day', frequency: 'daily', duration_min: 0 },
    // { value: 20, title: 'once a month', frequency: 'monthly', duration_min: 1 },
    // { value: 21, title: '2-4 times a month', frequency: 'monthly', duration_min: 2, duration_max: 4 },

];

export const FREQUENCY_TO_BACKEND_MAP: Record<string, ApiFrequencyOption> = {
    daily: { frequency: 'daily', duration_min: 1 }, // at least 1 minute a day
    weekly: { frequency: 'weekly', duration_min: 1 }, // at least 1 day a week
    fortnightly: { frequency: 'monthly', duration_min: 2 }, // at least 2 days a month
    monthly: { frequency: 'monthly', duration_min: 1}, // at least 1 day a month
    quarterly: { frequency: 'yearly', duration_min: 3 }, // at least 3 times a year
};

const FREQUENCY_CLIENT_MAP: Record<string, FrequencyOption> = {
    daily:  { value: 1, title: 'Daily', key: 'daily' }, // at least 1 minute a day
    weekly: { value: 2, title: 'Weekly', key: 'weekly' }, // at least 1 day a week
    fortnightly: { value: 3, title: 'Fortnightly', key: 'fortnightly' }, // at least 2 days a month
    monthly: { value: 4, title: 'Monthly', key: 'monthly' }, // at least 1 day a month
    quarterly: { value: 5, title: 'Quarterly', key: 'quarterly' }, // at least 3 times a year
};


export const SIMPLIFIED_FREQUENCY_OPTIONS: FrequencyOption[] = Object.values(FREQUENCY_CLIENT_MAP)


export function getSimplifiedFrequencyFromApproach(
    frequency: ActionFrequency,
    duration_min: number,
    duration_max?: number,
): FrequencyOption | null {
    switch (frequency) {
        case 'daily':
            return FREQUENCY_CLIENT_MAP.daily;
        case 'weekly':
            return FREQUENCY_CLIENT_MAP.weekly;
        case 'monthly':
            if (duration_min <= 1) {
                return FREQUENCY_CLIENT_MAP.monthly;
            } else {
                return FREQUENCY_CLIENT_MAP.fortnightly;
            }
        case 'yearly':
            return FREQUENCY_CLIENT_MAP.quarterly;
        default:
            return null;

    }
}
