<template>
    <v-card
        min-height="140"
        min-width="260"
        class="story-section-activity-card pa-5 rounded-12 d-flex flex-column"
        elevation="0"
    >
        <v-card-item class="pa-0">
            <div class="w-100 pb-5">
                <div class="text-heading-h4 cursor-pointer">
                    {{ item.title }}
                </div>
                <div class="mt-2">
                    <app-label :type="item.action_type" />
                </div>
            </div>
            <template v-slot:append>
                <v-btn
                    position="absolute"
                    style="right: 20px; top: 20px"
                    icon="mdi-close"
                    class="remove-icon"
                    variant="plain"
                    size="small"
                    @click="emit('close')"
                />
            </template>
        </v-card-item>
        <v-card-text class="pa-0">
            <div class="text-custom-neutral-grey-700 text-paragraph-md w-100">
                {{ item.description }}
            </div>

            <div
                v-if="item.long_description"
                class="text-custom-neutral-grey-700 text-paragraph-md w-100 mt-4"
            >
                <div v-html="formattedLongDescription" id="htmlContainer"></div>
            </div>
        </v-card-text>
        <v-card-text class="pa-0" v-if="links.length">
            <div class="action-item-frequency mt-5">
                <v-divider class="my-5" />
                <ul class="ml-4">
                    <li v-for="link in links" :key="link.url">
                        <a :href="link.url" target="_blank">{{ link.title }}</a>
                    </li>
                </ul>
            </div>
        </v-card-text>
        <v-card-text class="pa-0" v-if="formattedFrequency">
            <div class="action-item-frequency mt-5">
                <v-divider class="my-5" />
                <div class="text-paragraph-xs">Recommended approach:</div>
                <div class="text-paragraph-md text-custom-neutral-black">
                    {{ formattedFrequency }}
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { type SelectableChallengeAction } from '@/store/challenge-path/singleChallengeStore';
    import { formatFrequency } from '@/components/pathway/single-challenge/sequencing/formatFrequency';
    import { parseLinks } from '@/components/pathway/single-challenge/sequencing/parseLinks';

    const emit = defineEmits(['close']);
    const props = defineProps<{
        item: SelectableChallengeAction;
    }>();

    const formattedLongDescription = computed(() => {
        return (props.item.long_description ?? '').replace(/\n/g, '<br>');
    });

    const links = computed(() => {
        return parseLinks(props.item.links);
    });

    const formattedFrequency = computed(() => {
        return formatFrequency(
            props.item.frequency,
            props.item.duration_min,
            props.item.duration_max,
        );
    });
</script>

<style scoped lang="scss">
    .story-section-activity-card {
        border: 2px solid;
        border-color: rgb(var(--v-theme-custom-neutral-grey-200));
    }

    .action-item-frequency {
    }
</style>
