<template>
    <div>
        <app-plus-button
            class="mt-4 mr-2"
            @click="emit('fetch-new-activities')"
        >
            Show me more
        </app-plus-button>
        <app-button
            class="mt-4"
            append-icon="mdi-pencil"
            variant="outlined"
            @click="emit('create-own-activity')"
        >
            Create my own activity
        </app-button>
    </div>
</template>

<script setup lang="ts">
    const emit = defineEmits(['create-own-activity', 'fetch-new-activities']);
</script>
