import { useDisplay } from 'vuetify';
import { computed } from 'vue';

export function useIsDesktop() {
    const { mobile, name } = useDisplay();
    const isDesktop = computed(() => !mobile.value);
    const responsiveClass = computed(() =>
        isDesktop.value ? `desktop ${name.value}` : `mobile ${name.value}`,
    );
    return { isDesktop, isMobile: mobile, responsiveClass };
}
