<template>
    <div class="font-weight-700 text-heading-h2 w-100 mt-2 mb-3">
        Your story is {{ progress.toFixed() }}% complete!
    </div>

    <v-progress-linear
        :model-value="progress"
        color="secondary-orange"
        height="6"
        :rounded="true"
        class="w-100 d-print-none"
    ></v-progress-linear>

    <!--    <v-col cols="6" class="pa-0">-->
    <!--        <v-row no-gutters justify="space-between">-->
    <!--            <v-col cols="3" v-for="item in leftStoryCanvasItemProgress" :key="item.key">-->
    <!--                <StoryProgressBarItem :item="item" />-->
    <!--            </v-col>-->
    <!--        </v-row>-->
    <!--    </v-col>-->
    <!--    <v-col cols="6" class="pa-0">-->
    <!--        <v-row no-gutters justify="space-between">-->
    <!--            <v-col cols="3" v-for="item in rightStoryCanvasItemProgress" :key="item.key">-->
    <!--                <StoryProgressBarItem :item="item" />-->
    <!--            </v-col>-->
    <!--        </v-row>-->
    <!--    </v-col>-->
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useStoryStore } from '@/store/story/store';

    import { type StoryActivity, StoryActivityStatus } from '@/store/story/types';

    const store = useStoryStore();

    const progress = computed(() => {
        const total = store.storyActivities.length;
        const completedItems = store.storyActivities.filter((item: StoryActivity) => {
            return item.status === StoryActivityStatus.Completed;
        });

        return (completedItems.length * 100) / total;
    });
</script>
