export const CustomColors = {
    'custom-yellow': '#FFC32C',

    // TODO: remove this alias for custom-grey-900 for better readability, too much new variables in figma.
    //
    //
    // New colors set up by Hamish
    //
    //
    'custom-neutral-black': '#262626',

    'custom-neutral-grey-100': '#F6F6F6',
    'custom-neutral-grey-200': '#DCDCDC',
    'custom-neutral-grey-600': '#777',
    'custom-neutral-grey-700': '#545454',
    'custom-neutral-grey-800': '#434343',
    'custom-neutral-grey-900': '#262626',

    // @see here
    // https://www.figma.com/design/w3DMtUByEl8Z3ZHTaD0gAs/Actvo-v2?node-id=39-9305&t=X3cUbzf5OKWPNNe1-0
    'primary-yellow-light': '#FFF8E5',
    'secondary-blue': '#1638A5',
    'secondary-blue-light': '#E7EBF8',

    'secondary-orange': '#FB8625',
    'secondary-orange-light': '#FEEDDE',

    'secondary-pink': '#8B2687',
    'secondary-pink-light': '#F8EAF0',

    'secondary-purple': '#55199E',
    'secondary-purple-light': '#EEE8F7',

    'secondary-red': '#B83159',

    'tertiary-pink': '#CF7298',
    'tertiary-purple': '#571FBA',

    // ==================================
    //
    // Legacy colors setup by Kah
    //
    // TODO: Ideally in the near future, this colors should be deprecated in favour of the new ones listed above
    //
    // ==================================

    'custom-green-light': '#3A9994',
    'custom-green-mid': '#287580',
    'custom-green-deep': '#2C4648',

    'custom-purple-mid': '#6C3C99',
    'custom-purple-deep': '#160F29',


    'custom-independence-value': '#E6E6C7', // used in values card
    'custom-achievement-value': '#99D5EF', // used in values card
    'custom-empathy-value': '#F8EE62', // used in values card
    'custom-adventure-value': '#FCBE9F', // used in values card
    'custom-creativity-value': '#D2B9CE', // used in values card
    'custom-harmony-value': '#D7F682', // used in values card

    'custom-grey-50': '#FBFBFB',
    'custom-grey-100': '#F6F6F6',
    'custom-grey-200': '#F1F1F1',
    'custom-grey-300': '#E5E5E5',
    'custom-grey-400': '#C3C3C3',
    'custom-grey-500': '#A4A4A4',
    'custom-grey-600': '#7B7B7B',
    'custom-grey-700': '#666666',
    'custom-grey-800': '#474747',
    'custom-grey-900': '#262626',

    'custom-blue': '#0054a6',
    'custom-blue-rich': '#003471',
    'custom-blue-vibrant': '#20c4f4',
    'custom-red': '#c9252c',
    'custom-red-rich': '#ad040c',
    'custom-red-other': '#8a030a',
    'custom-red-light': '#FBD9E3',
};

export default CustomColors;
