import { marked, type Tokens } from 'marked';

const renderer = new marked.Renderer();

const linkRenderer = renderer.link;
/**
 * Override the `link` method to include `target="_blank"` without changing the original method
 * which is useful in preventing XSS attacks.
 *
 * @see https://github.com/markedjs/marked/issues/655#issuecomment-383226346
 */
renderer.link = ({ href, title, tokens }: Tokens.Link) => {
    const html = linkRenderer.call(renderer, { href, title, tokens } as Tokens.Link);
    return html.replace(/^<a /, '<a target="_blank" ');
};

// Set the custom renderer in `marked`
marked.setOptions({
    renderer,
});

/**
 * Formats Markdown syntax into an HTML string.
 *
 * This tries to compensate for links provided by Tavily in the backend, which are not in markdown format.
 * And, any Markdown text provided, that will read better as HTML.
 *
 * @param input - The input string that might contain the custom link syntax.
 * @returns The formatted string with links replaced by `<a>` tags.
 */
export function formatToHTML(input: string): string {
    // Convert the markdown to html
    //
    // Note:  Using the async: false option simplifies its use (e.g.: as computed properties in a vue file)
    // If at some point the rendering of the chat is blocking or rendering slowly,
    // we could review this flag.
    return marked(input, { async: false }).trim();
}
