import { defineStore } from 'pinia';
import type { CanvasInputItemType } from '@/components/canvas/canvas-input-item/CanvasInputItemType';
import { fetchStory, updateStorySectionStatementFeedback } from '@/services/story/service';
import { usePersonalValuesStore } from '@/store/personal-values/store';
import { usePersonalityTypeStore } from '@/store/personality-type/store';
import { useCurrentChallengeStore } from '@/store/current-challenges/store';
import { useFutureAspirationsStore } from '@/store/future-aspirations/store';
import StoryCurrentChallenges from '@/components/story/section/activity/content/current-challenges/StoryCurrentChallenges.vue';
import StoryFutureAspirations from '@/components/story/section/activity/content/future-aspirations/StoryFutureAspirations.vue';
import StoryPersonalityType from '@/components/story/section/activity/content/personality-type/StoryPersonalityType.vue';
import StoryPersonalValues from '@/components/story/section/activity/content/personal-values/StoryPersonalValues.vue';
import StoryPersonalStrengths from '@/components/story/section/activity/content/skills/StoryPersonalStrengths.vue';
import StorySkills from '@/components/story/section/activity/content/skills/StorySkills.vue';
import {
    DataState,
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
} from '@/store/common/dataState';
import { format } from 'date-fns';
import { useUsersStore } from '@/store/user/store';
import {
    careerHistoryRoute,
    currentChallengeRoute,
    futureAspirationRoute,
    learnedExperienceRoute,
    personalityTypeRoute,
    personalStrengthRoute,
    personalValuesRoute,
    skillsRoute,
} from '@/router/routes';
import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
import StoryLearnedExperiences from '@/components/story/section/activity/content/learned-experiences/StoryLearnedExperiences.vue';
import type { CanvasSkill } from '@/api/types/canvas/skills';
import { filterCanvasSkillsByType } from '@/store/skills/utils';
import { SkillType } from '@/store/skills/types';
import { fetchCanvasSkills } from '@/services/skills/service';
import { useCanvasStore } from '@/store/canvas/store';
import {
    type Story,
    type StoryActivity,
    StoryActivityKey,
    StoryActivityStatus,
    StorySectionId,
} from '@/store/story/types';
import { mapApiStory } from '@/store/story/mapStory';
import { useAppErrorStore } from '@/store/appErrorStore';
import { pollStory } from '@/services/story/pollStory';
import { fetchCanvasPersonalStrengths } from '@/services/personal-strengths/service';
import type { ApiStoryStatement } from '@/api/types/canvas/apiStory';
import { StoryStatementState } from '@/api/types/canvas/apiStory';

export type StoryStoreState = {
    story: Story | null;
    skills: CanvasSkill[];
    personalStrengths: CanvasSkill[];
    state: DataState;
    error: any | null;
    item: CanvasInputItemType | null;
};

const makeActivityStatus = (completed: boolean, inProgress?: boolean): StoryActivityStatus => {
    if (completed) {
        return StoryActivityStatus.Completed;
    }

    if (inProgress) {
        return StoryActivityStatus.InProgress;
    }

    return StoryActivityStatus.NotStarted;
};

export const useStoryStore = defineStore({
    id: 'story-store',
    state: (): StoryStoreState => ({
        story: {} as Story,
        skills: [],
        personalStrengths: [],
        item: null,

        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        learnedSkills(state): CanvasSkill[] {
            return filterCanvasSkillsByType(state.skills, SkillType.Learned);
        },
        technicalSkills(state): CanvasSkill[] {
            return filterCanvasSkillsByType(state.skills, SkillType.Technical);
        },
        displayDate(state): string {
            const story = state.story;
            const date = story?.updatedAt ? new Date(story.updatedAt) : new Date();
            return format(date, 'dd/MM/yy');
        },
        storyName(state): string {
            const user = useUsersStore().user;
            if (!user || !user.first_name) {
                return 'Your';
            }

            return `${user.first_name}'s`;
        },
        storyActivities(state): StoryActivity[] {
            return [
                ...this.whatIOffserActivities,
                ...this.whoIAmActivities,
                ...this.whatIAmWorkingOnActivities,
            ];
        },
        allStoryCompleted(state): boolean {
            return this.storyActivities.every((a) => a.status === StoryActivityStatus.Completed);
        },
        whoIAmActivities(state): StoryActivity[] {
            return [
                this.personalStrengthActivity,
                this.personalityTestActivity,
                this.personalValueActivity,
            ];
        },
        whatIOffserActivities(): StoryActivity[] {
            return [this.skillsActivity, this.learnedExperienceActivity];
        },
        whatIAmWorkingOnActivities(state): StoryActivity[] {
            return [this.currentChallengeActivity, this.futureAspirationActivity];
        },
        personalValueActivity(state): StoryActivity {
            const store = usePersonalValuesStore();

            return {
                key: StoryActivityKey.PersonalValues,
                title: 'Values',
                status: makeActivityStatus(store.isAreaComplete, store.isAreaStarted),
                component: StoryPersonalValues,
                to: personalValuesRoute,
            };
        },
        uvp(state): ApiStoryStatement | null {
            return state.story?.uvp || null;
        },
        personalityTestActivity(state): StoryActivity {
            const store = usePersonalityTypeStore();

            return {
                key: StoryActivityKey.PersonalityType,
                title: 'Personality Type',
                status: makeActivityStatus(store.isAreaComplete),
                component: StoryPersonalityType,
                to: personalityTypeRoute,
            };
        },
        personalStrengthActivity(state): StoryActivity {
            const amountOfSoftSkills = state.personalStrengths.length;
            return {
                key: StoryActivityKey.PersonalStrengths,
                title: 'Personal strengths',
                status: makeActivityStatus(amountOfSoftSkills >= 2, amountOfSoftSkills > 0),
                component: StoryPersonalStrengths,
                to: personalStrengthRoute,
            };
        },
        skillsActivity(state): StoryActivity {
            const amountOfLearnedSkills = this.learnedSkills.length;
            const amountOfTechnicalSkills = this.technicalSkills.length;
            const completed = amountOfLearnedSkills > 2 && amountOfTechnicalSkills > 0;
            const inProgress = amountOfLearnedSkills > 0 || amountOfTechnicalSkills > 0;
            return {
                key: StoryActivityKey.Skills,
                title: 'My skills',
                status: makeActivityStatus(completed, inProgress),
                component: StorySkills,
                to: skillsRoute,
            };
        },
        learnedExperienceActivity(state): StoryActivity {
            const store = useLearnedExperiencesStore();

            return {
                key: StoryActivityKey.LearnedExperiences,
                title: 'My memorable experiences',
                status: makeActivityStatus(store.isAreaComplete),
                component: StoryLearnedExperiences,
                to: store.isAreaComplete ? learnedExperienceRoute : careerHistoryRoute,
            };
        },
        currentChallengeActivity(state): StoryActivity {
            const store = useCurrentChallengeStore();

            return {
                key: StoryActivityKey.CurrentChallenges,
                title: 'Challenges',
                status: makeActivityStatus(store.isAreaComplete, store.isAreaStarted),
                component: StoryCurrentChallenges,
                to: currentChallengeRoute,
            };
        },
        futureAspirationActivity(state): StoryActivity {
            const store = useFutureAspirationsStore();

            return {
                key: StoryActivityKey.FutureAspirations,
                title: 'Future aspirations',
                status: makeActivityStatus(store.isAreaComplete),
                component: StoryFutureAspirations,
                to: futureAspirationRoute,
            };
        },
        _whoIAmStores() {
            return [usePersonalValuesStore(), usePersonalityTypeStore()];
        },
        _whatIOffserStores() {
            return [useLearnedExperiencesStore()];
        },
        _whatIAmWorkingOnStores() {
            return [useCurrentChallengeStore(), useFutureAspirationsStore()];
        },
        _stores(): any[] {
            return [
                ...this._whoIAmStores,
                ...this._whatIOffserStores,
                ...this._whatIAmWorkingOnStores,
            ];
        },
    },
    actions: {
        async load() {
            console.info('Loading canvas...');
            setInLoading(this);

            const { accessToken, canvasId } = await useCanvasStore().makeContext();

            try {
                await Promise.all(
                    this._stores.map((s) => {
                        return s.load(canvasId, accessToken);
                    }),
                );

                this.skills = await fetchCanvasSkills(canvasId, accessToken);
                this.personalStrengths = await fetchCanvasPersonalStrengths(canvasId, accessToken);

                const apiStory = await fetchStory(canvasId, accessToken);

                this.story = mapApiStory(apiStory);

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        async saveFeedback(
            sectionId: StorySectionId,
            statement: ApiStoryStatement,
            feedback: boolean,
        ) {
            console.log(`Saving feedback: ${feedback} for ${statement}`);
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                // Update the section feedback state to instantly reflect the user's feedback state in the UI
                statement.feedback = feedback;

                await updateStorySectionStatementFeedback(
                    canvasId,
                    statement.id,
                    { section: sectionId, feedback: feedback },
                    accessToken,
                );

                // instead of fetching the story again, we can update the story object directly

                if (feedback) {
                    // no need to update the section state if the feedback is positive
                } else {
                    statement.state = StoryStatementState.New;
                    statement.content = null;

                    console.log(
                        'Negative feedback received. Re-triggering the section generation. Setting the section to in progress',
                    );
                    // at the moment, if the user provides a negative feedback,
                    // we re-trigger the new generation of the statement
                    // this is a temporary solution until we have a better way to handle this
                    // this assumes the backend will trigger the update of the statement

                    const storyUpdated = await pollStory(
                        canvasId,
                        (s) => {
                            const _getSection = (_story: Story) => {
                                switch (sectionId) {
                                    case StorySectionId.WhoIAm:
                                        return _story.whoIAm;
                                    case StorySectionId.WhatIOffer:
                                        return _story.whatIOffer;
                                    case StorySectionId.WhatIAmWorkingOn:
                                        return _story.whatIAmWorkingOn;
                                    case StorySectionId.UVP:
                                        return _story.uvp;
                                    default:
                                        return false;
                                }
                            };
                            const fetchedStory = mapApiStory(s);
                            const sectionBeingGenerated = _getSection(fetchedStory);

                            if (sectionBeingGenerated) {
                                // heuristic to check if the section is being generated
                                // we stop when the new section feedback is null (meaning it is a new statement)
                                if (sectionBeingGenerated.state === StoryStatementState.Completed) {
                                    console.info(
                                        'Section state has been updated to Completed, meaning the section is being generated',
                                    );
                                    return true;
                                } else {
                                    console.info('Section state is not completed yet');
                                    return false;
                                }
                            } else {
                                console.info('Section not found while polling');
                                return false;
                            }
                        },
                        {},
                        accessToken,
                    );

                    this.story = mapApiStory(storyUpdated);
                }
            });
        },
    },
});
