<template>
    <app-page>
        <app-page-header
            section-title="Challenge pathway:"
            :title="store.challenge?.description"
            @show-tutorial="onShowTutorial"
        />
        <ChallengePathTutorial v-model="showTutorial" :navigate-back="tutorialNavigateBack" />

        <app-page-content>
            <template #intro>
                <app-page-content-intro
                    title="Choose activities that will help with your challenge"
                >
                    We recommend you start with three activities. If you don't solve the challenge
                    you can always come back and try some other activities later.

                    <br />
                    <div class="my-2">
                        <app-label class="mr-3" :type="PlanActionItemType.ExperienceActionType" />
                        <span
                            >Things you can do on your own. These are most suited to people who
                            prefer ‘self-learning’ or reflective activities.
                        </span>
                    </div>
                    <div class="my-2">
                        <app-label class="mr-3" :type="PlanActionItemType.ConnectActionType" />
                        <span
                            >Work with or learn from others. These are most suited to people who
                            enjoy ‘learning by doing’ or ‘social learning’.</span
                        >
                    </div>
                    <div class="my-2">
                        <app-label class="mr-3" :type="PlanActionItemType.NewSkillActionType" />
                        <span
                            >Up-skill or build a new capability. These are more formal activities or
                            methods for learning a skill and that may require higher focus or
                            application.
                        </span>
                    </div>
                </app-page-content-intro>
            </template>

            <div v-if="!isLoadingMoreActivities">
                <SingleChallengeNewActionListItem
                    v-for="(action, index) in store.selectableActions"
                    :key="index"
                    :item="action"
                    class="mb-2 w-100"
                    @read-more-click="onReadMore(action)"
                />

                <div v-if="store._timeoutError">
                    <div class="mt-4">
                        Sorry! Looks like your Pathway didn't load this time. Refresh and let's
                        <span class="px-0 cursor-pointer text-secondary-blue" @click="loadMore">
                            try again.</span
                        >
                    </div>
                </div>

                <SingleChallengeActionForm v-if="store.current" />
                <SingleChallengeActionButtons
                    v-else
                    @create-own-activity="store.addActivity"
                    @fetch-new-activities="loadMoreActivities"
                />
            </div>
            <Loader v-else
                class="fill-height"
                :title="loadingStore.title"
                :subtitle="loadingStore.subtitle"
            />
        </app-page-content>

        <v-dialog close-on-back max-width="640" v-model="showReadModal">
            <SingleChallengeNewActionListItemReadMore
                v-if="current"
                :item="current"
                class="mb-2 w-100 fill-height"
                @close="showReadModal = false"
            />
        </v-dialog>

        <app-page-footer>
            <template v-slot:actions>
                <CanvasSaveProgressActions
                    close-text="Back"
                    :close-to="challengePathRoute"
                    @on-submit="onContinueToSequencing"
                    title="Continue"
                    :submit-disabled="store.selected.length < 3"
                    :submit-tooltip="'Please select at least 3 actions'"
                />
                <v-spacer />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.ChallengePath" />
    </app-page>

    <SingleChallengeNewGuide />
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { challengePathRoute, challengeSequencingRoute } from '@/router/routes';
    import { useActivityStore } from '@/store/activity/store';
    import {
        type SelectableChallengeAction,
        useSingleChallengePathStore,
    } from '@/store/challenge-path/singleChallengeStore';
    import { useRoute, useRouter } from 'vue-router';
    import { verify } from '@/store/verify';
    import { PlanActionItemType } from '@/store/plan/common/types';
    import SingleChallengeNewActionListItem from '@/components/pathway/single-challenge/new/SingleChallengeNewActionListItem.vue';
    import CanvasSaveProgressActions from '@/components/canvas/CanvasSaveProgressActions.vue';
    import ChallengePathTutorial from '@/components/pathway/ChallengePathTutorial.vue';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import SingleChallengeNewActionListItemReadMore from '@/components/pathway/single-challenge/new/SingleChallengeNewActionListItemReadMore.vue';
    import { previousRoute } from '@/router';
    import { RoutesName } from '@/router/routesName';
    import SingleChallengeNewGuide from '@/components/pathway/single-challenge/new/SingleChallengeNewGuide.vue';
    import SingleChallengeActionForm from '@/components/pathway/single-challenge/SingleChallengeActionForm.vue';
    import { useTutorial } from '@/store/useTutorial';
    import SingleChallengeActionButtons from '@/components/pathway/single-challenge/SingleChallengeActionButtons.vue'
    import Loader from '@/components/common/Loader.vue';
    import { useLoadingStore } from '@/store/loadingStore';

    const current = ref<SelectableChallengeAction | null>(null);
    const showReadModal = ref(false);
    const { showTutorial, tutorialNavigateBack, onShowTutorial } = useTutorial();

    const store = useSingleChallengePathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    const router = useRouter();
    const loadingStore = useLoadingStore();

    const previous = previousRoute.value;
    const challengeId = Number(verify(useRoute().params.id, 'No challenge id'));

    // TODO: Consider moving this logic to something more generic
    // Load the challenge and canvas if the previous route was not
    // the single challenge sequencing for the same challenge id
    const needsLoad =
        previous?.name !== RoutesName.SingleChallengeSequencing &&
        previous?.params?.id !== challengeId.toString();
    if (needsLoad) {
        console.log(
            `Loading single challenge ${challengeId}. Previous route is: ${previous?.fullPath}`,
        );
        await canvasStore.load();
        await store.loadNew(challengeId);
    } else {
        console.log(
            `Avoid loading single challenge ${challengeId}. Previous route is ${previous?.fullPath}`,
        );
    }

    const isLoadingMoreActivities = ref(false);
    const loadMoreActivities =  async () => {
        try {
            isLoadingMoreActivities.value = true;
            await store.loadMore(challengeId)
        } finally {
            isLoadingMoreActivities.value = false;
        }
    };

    onMounted(async () => {
        await activityStore.addEntryActivity(UserActivityArea.ChallengePath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });

    const onReadMore = (item: SelectableChallengeAction) => {
        showReadModal.value = true;
        current.value = item;
    };

    const loadMore = async () => {
        location.reload();
    };
    // await store.loadMore(challengeId);
    // };

    const onContinueToSequencing = () => {
        router.push(challengeSequencingRoute(challengeId));
    };
</script>
