import {
    type GuideEnterEvent,
    GuideEventType,
    type GuideLeaveEvent,
    type GuideQuestionEvent,
    type GuideSuggestionEvent,
    GuideSupportedScreen,
} from '@/store/guide/types';
import { values } from 'lodash';
import { EventFactory } from '@/store/guide/event-factory/eventFactory';
import type { CanvasCurrentPlanStoreState } from '@/store/plan/current/types';

export class ChallengePathEventFactory extends EventFactory<CanvasCurrentPlanStoreState> {
    screen = GuideSupportedScreen.ChallengePath;

    public enter(): GuideEnterEvent {
        return this.make<GuideEnterEvent>(GuideEventType.Enter, {
            context: values(this.contextState.editablePlan),
        });
    }

    leave(): GuideLeaveEvent {
        return this.make<GuideLeaveEvent>(GuideEventType.Leave, {
            context: values(this.contextState.editablePlan),
        });
    }

    question(message: string): GuideQuestionEvent {
        return this.make<GuideQuestionEvent>(GuideEventType.Question, {
            q: message,
        });
    }

    suggestion(): GuideSuggestionEvent {
        return this.make<GuideSuggestionEvent>(GuideEventType.Suggestion, {
            context: values(this.contextState.editablePlan),
        });
    }
}
