<template>
    <v-card min-height="80" class="rounded-8 pa-5 bg-white app-card box-shadow-none">
        <v-card-text class="mx-0 pa-0 text-paragraph-md">
            <v-row no-gutters class="mb-3">
                <v-text-field
                    :disabled="isSaving"
                    variant="outlined"
                    v-model="current!.description"
                    label="Skill"
                    :rules="[store.isAddingNew ? skillNewIsValidRule : skillEditIsValidRule]"
                    persistent-placeholder
                    placeholder="What's the name of this skill?"
                />
            </v-row>

            <v-row no-gutters class="radio-group">
                <!-- Note: v-radio-group in vuetify does not have variant=outlined  -->
                <v-radio-group
                    class="ml-n2"
                    label="Skill Type"
                    :model-value="current?.type"
                    @update:model-value="onChangeType"
                >
                    <v-radio
                        v-for="(option, index) in switchOptions"
                        :key="index"
                        :disabled="isSaving"
                        :label="option.label"
                        :value="option.value"
                    />
                </v-radio-group>
            </v-row>
        </v-card-text>

        <v-card-actions class="pa-0">
            <SubmitButton
                text="Save"
                :disabled="store.isAddingNew && !isSubmitEnabled"
                @click="onSaveClick"
            />
            <CloseButton variant="plain" @click="store.cancelEdit" text="Cancel" />
            <v-spacer />
            <app-button
                v-if="!store.isAddingNew"
                variant="plain"
                prepend-icon="mdi-close"
                @click="onDelete"
                >Remove Skill
            </app-button>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, onBeforeMount, ref } from 'vue';
    import { storeToRefs } from 'pinia';
    import { verify } from '@/store/verify';
    import { useSkillsStore } from '@/store/skills/store';
    import { type EditableSkill, SkillType } from '@/store/skills/types';
    import {
        type CanvasSkill,
        isCanvasSkill,
        isInferredCanvasSkill,
    } from '@/api/types/canvas/skills';
    import { cloneDeep } from 'lodash';
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import CloseButton from '@/components/footer-actions/CloseButton.vue';

    const store = useSkillsStore();
    const { current } = storeToRefs(store);

    const originalValue = ref<EditableSkill | null>(null);

    const onChangeType = (value: SkillType | null) => {
        current.value!.type = value;
    };

    onBeforeMount(() => {
        originalValue.value = cloneDeep(current.value!);
    });

    const props = defineProps<{
        item: CanvasSkill;
    }>();

    const switchOptions = computed(() => {
        return [
            {
                label: 'Learned',
                color: 'secondary-blue',
                value: SkillType.Learned,
            },
            {
                label: 'Technical',
                color: 'secondary-pink',
                value: SkillType.Technical,
            },
        ];
    });

    const isSubmitEnabled = computed(() => {
        const value = verify(current.value, 'current.value must exist while editing');

        const requiredData =
            value.type && value.description?.length !== undefined && value.description?.length > 0;

        if (requiredData) {
            if (store.isAddingNew) {
                return skillNewIsValidRule(value.description) === true;
            } else {
                const original = verify(
                    originalValue.value,
                    'originalValue must exist while editing',
                );
                if (
                    (original.type !== value.type || value.description !== original.description) &&
                    skillEditIsValidRule(value.description) === true
                ) {
                    return true;
                }
            }
        }

        return false;
    });

    const isSaving = ref(false);

    const onSaveClick = async () => {
        try {
            isSaving.value = true;
            if (store.isAddingNew) {
                await store.createSkill(current.value!);
            } else {
                if (isSubmitEnabled.value) {
                    await store.updateSkill(current.value!);
                } else {
                    store.cancelEdit();
                    console.debug('Avoid triggering save when not changes');
                }
            }
        } finally {
            isSaving.value = false;
        }
    };

    const onDelete = async () => {
        try {
            isSaving.value = true;
            await store.removeSkill(props.item);
        } finally {
            isSaving.value = false;
        }
    };

    const skillNewIsValidRule = (v: string) => {
        if (v.length === 0) {
            return 'This field is required';
        }

        let error = null;
        store.values.forEach((item) => {
            if (item.description === v) {
                error = 'You already added this skill';
                return;
            }
        });

        return error ?? true;
    };

    const skillEditIsValidRule = (v: string) => {
        if (v.length === 0) {
            return 'This field is required';
        }

        let error = null;
        store.values.forEach((item) => {
            if (item.description === v) {
                if (
                    isCanvasSkill(item) &&
                    isCanvasSkill(current.value) &&
                    item.id !== current.value.id
                ) {
                    error = 'You already added this skill';
                    return;
                }
                if (
                    isInferredCanvasSkill(item) &&
                    isInferredCanvasSkill(current.value) &&
                    item.id !== current.value.id
                ) {
                    error = 'You already added this skill';
                    return;
                }
            }
        });

        return error ?? true;
    };
</script>

<style lang="scss">
    .v-card.app-card {
        border: 1px dashed;
        border-color: rgb(var(--v-theme-custom-grey-700));
        border-image-slice: 4;
    }

    .radio-group {
        .v-input__control .v-label:first-child {
            font-size: 12px !important;
        }
    }
</style>
