<template>
    <v-container class="ma-0 before-pdf-break-page story-section-container" fluid :class="cssClass">
        <v-row
            class="w-100 pa-0"
            no-gutters
            variant="flat"
            :color="backgroundColor"
            :class="isDesktop ? 'ga-16' : 'ga-10'"
        >
            <v-col :cols="isDesktop ? 3 : 12" class="pa-0 story-section-title-container">
                <v-card :color="backgroundColor" variant="flat">
                    <v-row no-gutters class="story-section-title-container-image">
                        <img :src="props.section.image" class="w-50 h-100" />
                    </v-row>
                    <div
                        class="text-heading-h1 font-weight-700 w-100 print-section-title story-section-title-container-title"
                        :class="isDesktop ? 'mt-10' : 'mt-5'"
                    >
                        {{ props.section.title }}
                    </div>
                    <div
                        class="text-paragraph-lg text-left w-100 d-print-none"
                        :class="isDesktop ? 'mt-2' : 'mt-5'"
                    >
                        {{ props.section.description }}
                    </div>
                </v-card>
            </v-col>
            <v-col :cols="isDesktop ? true : 12" class="story-section-content-container">
                <StorySectionStatement
                    v-if="
                        section.statement?.content &&
                        section.statement?.state === StoryStatementState.Completed
                    "
                    :is-feedback-updating="feedbackUpdating"
                    :section="section"
                    @on-feedback="onFeedback"
                    class="mb-10 print-story-section-statement-container"
                />
                <StorySectionStatementInProgress
                    v-else-if="
                        section.statement?.state === StoryStatementState.Progress ||
                        section.statement?.state === StoryStatementState.New
                    "
                    :section="section"
                    class="mb-10 print-story-section-statement-container"
                />
                <!-- Note: when no statement is displayed as subtitle inside next component -->

                <StorySectionActivities class="ma-0" :items="props.section.activities" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed, ref } from 'vue';
    import StorySectionStatement from '@/components/story/section/statement/StorySectionStatement.vue';
    import StorySectionActivities from '@/components/story/section/activity/StorySectionActivities.vue';
    import StorySectionStatementInProgress from '@/components/story/section/statement/StorySectionStatementInProgress.vue';
    import { useStoryStore } from '@/store/story/store';
    import { StorySectionId, type StorySectionItem } from '@/store/story/types';
    import { StoryStatementState } from '@/api/types/canvas/apiStory';

    const { responsiveClass, isDesktop } = useIsDesktop();

    const props = defineProps<{
        section: StorySectionItem;
    }>();

    const cssClass = computed(() => {
        return {
            [responsiveClass.value]: true,
            [props.section.id]: true,
        };
    });

    const backgroundColor = computed(() => {
        switch (props.section.id) {
            case StorySectionId.WhatIOffer:
                return 'secondary-blue-light';
            case StorySectionId.WhoIAm:
                return 'secondary-purple-light';
            case StorySectionId.WhatIAmWorkingOn:
                return 'secondary-pink-light';
            default:
                throw new Error('Invalid section type');
        }
    });

    const store = useStoryStore();

    const feedbackUpdating = ref(false);
    const onFeedback = async (value: boolean) => {
        if (!props.section.statement) {
            return;
        }

        try {
            feedbackUpdating.value = true;
            await store.saveFeedback(props.section.id, props.section.statement, value);
        } finally {
            feedbackUpdating.value = false;
        }
    };
</script>

<style scoped lang="scss">
    .what-i-offer {
        --section-background: rgb(var(--v-theme-secondary-blue-light));
    }

    .who-i-am {
        --section-background: rgb(var(--v-theme-secondary-purple-light));
    }

    .what-i-am-working-on {
        --section-background: rgb(var(--v-theme-secondary-pink-light));
    }

    .story-section-container {
        border-radius: 30px;
        background: var(--section-background);

        &.desktop {
            padding: 80px;
        }

        &.mobile {
            padding: 32px 20px;
        }
    }
</style>
