<template>
    <BeforeMainContent>
        <v-row no-gutters class="mx-0 mb-1 w-100 d-print-none">
            <StoryProgress />
        </v-row>

        <v-row
            no-gutters
            class="mx-0 mb-1 w-100 d-print-none align-center"
            v-if="activityStore.lastCompleted"
        >
            <span class="mt-4 mb-2 mr-8">
                <span class="font-weight-regular text-subtitle-2 text-custom-grey-700">
                    Last activity
                </span>
                <div class="text-subtitle-1 font-weight-600 w-100">
                    {{ lastCompletedDate }}
                </div>
            </span>
            <span class="mt-4 mb-2">
                <span class="font-weight-regular text-subtitle-2 text-custom-grey-700">
                    Last completed
                </span>
                <div class="text-subtitle-1 font-weight-600 w-100">
                    {{ lastCompletedActivityLabel }}
                </div>
            </span>
            <v-spacer />
            <app-button v-if="showPrintStory && isDesktop" @click="onPrintStory" class="mt-4">
                Print Story
            </app-button>
            <StoryPrintPdfButton v-if="false" />
        </v-row>

        <!--
            Note: This header in only printed when using @media print
            Ideally, we should override only the print styles in the component,
            but given both components are not very similar, a new layout is implemented.
        -->
        <v-row
            no-gutters
            class="mx-0 mb-1 w-100 d-print-only-flex align-center"
            v-if="activityStore.lastCompleted"
        >
            <v-col cols="7">
                <div class="text-heading-h1 story-printing-heading my-0 d-print-only">
                    {{ userStore.displayName ?? 'Your' }}'s story
                </div>
            </v-col>
            <v-col>
                <div class="d-flex justify-end ga-4 mt-4">
                    <div class="">
                        <span class="font-weight-regular text-subtitle-2 text-custom-grey-700">
                            Last activity
                        </span>
                        <div class="text-subtitle-1 font-weight-600 w-100">
                            {{ lastCompletedDate }}
                        </div>
                    </div>
                    <div>
                        <span class="font-weight-regular text-subtitle-2 text-custom-grey-700">
                            Last completed
                        </span>
                        <div class="text-subtitle-1 font-weight-600 w-100">
                            {{ lastCompletedActivityLabel }}
                        </div>
                    </div>
                    <div class="ml-4 mr-8">
                        <LogoCircle height="73" width="73" :eager="true" />
                    </div>
                </div>
            </v-col>
        </v-row>
    </BeforeMainContent>
</template>

<script setup lang="ts">
    import StoryProgress from '@/components/story/StoryProgress.vue';
    import { computed } from 'vue';
    import { useActivityStore } from '@/store/activity/store';
    import { format } from 'date-fns';
    import { UserActivityArea } from '@/api/types/userActivity';
    import BeforeMainContent from '@/components/layout/BeforeMainContent.vue';
    import { useUsersStore } from '@/store/user/store';
    import LogoCircle from '@/components/header/PrintLogoCircle.vue';
    import StoryPrintPdfButton from '@/components/story/StoryPrintPdfButton.vue';
    import { useStoryStore } from '@/store/story/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const activityStore = useActivityStore();
    const storyStore = useStoryStore();
    const userStore = useUsersStore();

    const lastCompletedDate = computed(() => {
        const createdAt = activityStore.lastCompleted?.created_at;
        return createdAt ? format(new Date(createdAt), 'dd/MM/yy') : null;
    });

    const lastCompletedActivityLabel = computed(() => {
        const activity = activityStore.lastCompleted;

        switch (activity?.area) {
            case UserActivityArea.CurrentChallenges:
                return 'Current challenges';
            case UserActivityArea.FutureAspiration:
                return 'Future aspiration';
            case UserActivityArea.LearnedExperiences:
                return 'Memorable Experiences';
            case UserActivityArea.PersonalStrengths:
                return 'Personal strengths';
            case UserActivityArea.PersonalValues:
                return 'Personal values';
            case UserActivityArea.PersonalityType:
                return 'Personality type';
            case UserActivityArea.Skills:
                return 'Skills';
            case UserActivityArea.UniqueValueProposition:
                return 'Unique Value Proposition';
            default:
                break;
        }
    });

    const { isDesktop } = useIsDesktop();

    const showPrintStory = computed(() => {
        return storyStore.story?.uvp;
    });

    const onPrintStory = () => {
        window.print();
    };
</script>
