<template>
    <v-card
        min-width="100"
        min-height="60"
        elevation="0"
        variant="flat"
        class="rounded-8 pa-8 py-6 border-2-grey-200"
        :class="editable ? 'cursor-pointer' : 'no-cursor-pointer'"
        @click="editable ? store.setEdit(props.item) : false"
    >
        <v-card-item class="pa-0">
            <span class="mx-0 font-weight-600 text-heading-h4">
                {{ props.item.title }}
            </span>
            <template v-slot:append>
                <v-icon class="align-start mr-0" v-if="editable"> mdi-pencil</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="ma-0 mt-2 pa-0 text-custom-neutral-black text-paragraph-sm">
            <span class="text-uppercase mr-0">{{ props.item.company_name }}, </span>
            <span class="text-uppercase">{{ displayDuration }}</span>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { UserJob } from '@/api/types/userJob';
    import { useUserJobStore } from '@/store/user-job/store';
    import { formatJobDuration } from '@/components/profile/career-history/formatJobDuration';

    const store = useUserJobStore();
    const props = withDefaults(
        defineProps<{
            item: UserJob;
            editable?: boolean;
        }>(),
        { editable: true },
    );

    const displayDuration = computed(() => {
        return formatJobDuration(props.item);
    });

    const cardColor = computed(() => {
        return 'white';
    });
</script>
