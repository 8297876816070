import type { User } from '@/api/types/user';

export function getUserInitials(user: User): string {
    const firstNameInitial = user.first_name ? user.first_name.charAt(0) : '';
    const lastNameInitial = user.last_name ? user.last_name.charAt(0) : '';

    return (firstNameInitial + lastNameInitial).toUpperCase();
}

/**
 * Returns the display name of a user.
 */
export function userDisplayName(user: User): string | null {
    const firstName = user.first_name;
    const lastName = user.last_name;

    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    } else if (firstName) {
        return firstName;
    } else {
        // I think last name only does not makes sense as a display name

        return null;
    }
}