<template>
    <v-select
        :model-value="modelValue"
        @update:model-value="(v) => emit('update:modelValue', v)"
        :items="options"
        return-object
        item-value="value"
        label="Approach"
    />
</template>

<script setup lang="ts">
    import type { FrequencyOption } from '@/components/pathway/single-challenge/sequencing/frequencySelector';

    const emit = defineEmits(['update:modelValue']);

    const props = defineProps<{
        modelValue: FrequencyOption | null;
        options: FrequencyOption[];
    }>();
</script>
