<template>
    <app-page>
        <SingleChallengePageHeader />

        <app-page-content>
            <template #intro>
                <app-page-content-intro class="">
                    <template #title>
                        <div class="text-heading-h1 font-weight-regular">
                            How are you going with
                            <span class="font-weight-700">{{
                                store.challenge?.description.toLowerCase()
                            }}</span
                            >?
                        </div>
                    </template>

                    <SingleChallengeProgressCard />

                    <SingleChallengeUpdateProgressViewOptions />
                </app-page-content-intro>
            </template>

            <v-container
                class="pa-0 d-flex challenge-sequencing-container"
                v-for="(action, index) in store.actions"
                :key="`${index}`"
            >
                <app-sorting-controls
                    :position="index + 1"
                    :total="store.actions.length"
                    :hide-sort-buttons="true"
                    line-color="grey"
                />
                <SingleChallengeActionListItem
                    :key="index"
                    :item="action"
                    class="mb-2 w-100"
                    :is-editable="false"
                    @mark-as-completed="onMarkAsCompletedClick(action)"
                />
            </v-container>

            <app-guide-chat-activator />
            <app-guide-chat-dialog :screen="GuideSupportedScreen.ChallengePath" />
        </app-page-content>

        <app-page-footer>
            <template v-slot:actions>
                <CanvasSaveProgressActions
                    close-text="Back"
                    :close-to="challengePathRoute"
                    submit-hide
                    @on-submit="onMarkChallengeComplete"
                    title="Back"
                />
            </template>
        </app-page-footer>

        <SingleChallengeEditActionListItemFeedback
            v-model="showFeedbackDialog"
            @feedback-save="onFeedbackSave"
        />

        <AppGuideModal
            v-model="showSuccess"
            title="Wow you really showed that challenge who’s boss!"
            action-text="Continue"
            :action-to="challengePathRoute"
        >
            <template #default>
                Give yourself a pat on the back for overcoming this challenge. Now is a great to to
                reflect on the experience.
            </template>
        </AppGuideModal>

        <PageActivity :area="UserActivityArea.ChallengePath" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { challengePathRoute } from '@/router/routes';
    import { useActivityStore } from '@/store/activity/store';
    import SingleChallengePageHeader from '@/components/pathway/single-challenge/SingleChallengePageHeader.vue';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { useRoute } from 'vue-router';
    import { verify } from '@/store/verify';
    import CanvasSaveProgressActions from '@/components/canvas/CanvasSaveProgressActions.vue';
    import SingleChallengeActionListItem from '@/components/pathway/single-challenge/edit/SingleChallengeEditActionListItem.vue';
    import AppSortingControls from '@/components/common/AppSortingControls.vue';
    import SingleChallengeEditActionListItemFeedback from '@/components/pathway/single-challenge/edit/SingleChallengeEditActionListItemFeedback.vue';
    import type { CurrentChallengeAction } from '@/api/types/plan/currentChallengeAction';
    import SingleChallengeProgressCard from '@/components/pathway/single-challenge/edit/SingleChallengeProgressCard.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import SingleChallengeUpdateProgressViewOptions from '@/views/pathways/SingleChallengeUpdateProgressViewOptions.vue';
    import { GuideSupportedScreen } from '@/store/guide/types';

    const store = useSingleChallengePathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    await canvasStore.load();
    const challengeId = Number(verify(useRoute().params.id, 'No challenge id'));
    await store.loadEdit(challengeId);

    const showFeedbackDialog = ref(false);

    const showSuccess = ref(false);

    const { isDesktop } = useIsDesktop();

    onMounted(async () => {
        await activityStore.addEntryActivity(UserActivityArea.ChallengePath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });

    const onMarkAsCompletedClick = (item: CurrentChallengeAction) => {
        store.onMarkAsCompleted(item);

        showFeedbackDialog.value = true;
    };

    const onFeedbackSave = () => {
        if (store.isCompleted) {
            showSuccess.value = true;
        }
    };

    const onMarkChallengeComplete = async () => {};
</script>
