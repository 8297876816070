<template>
    <app-page>
        <app-page-header title="Future aspiration" @show-tutorial="onShowTutorial" />
        <FutureAspirationGuideModalExplanation
            v-model="showTutorial"
            :navigate-back="tutorialNavigateBack"
        />

        <app-page-content>
            <template v-slot:intro>
                <app-page-content-intro title="Visualise your ideal future">
                    We want to help you progress on your path. To do so, it would be useful for us
                    to understand your goal. Think ahead to the next 5 to 10 years. What is your
                    career ambition?
                </app-page-content-intro>
            </template>
            <FutureAspirationsForm v-if="!store.value" />
            <FutureAspirationsContent v-else :value="store.value" />
        </app-page-content>
        <app-page-footer>
            <template v-slot:errors>
                <SubmitNotification
                    :store="store"
                    :activity-area="UserActivityArea.FutureAspiration"
                />
            </template>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions
                    @on-submit="onSaveClick"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty"
                />
                <WarningChangesWillBeLost
                    :value="store.isDirty"
                    @on-discard-changes="onDiscardChanges"
                    @on-submit="onSaveClick"
                />
            </template>
        </app-page-footer>

        <FutureAspirationsGuide v-if="store.isLoaded" />

        <PageActivity :area="UserActivityArea.FutureAspiration" />
    </app-page>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import FutureAspirationsForm from '@/components/canvas/future-aspirations/FutureAspirationsForm.vue';
    import FutureAspirationsContent from '@/components/canvas/future-aspirations/FutureAspirationsContent.vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import { useFutureAspirationsStore } from '@/store/future-aspirations/store';
    import FutureAspirationsGuide from '@/components/canvas/future-aspirations/FutureAspirationsGuide.vue';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { storyRoute } from '@/router/routes';
    import { useRouter } from 'vue-router';
    import { useActivityStore } from '@/store/activity/store';
    import { useTutorial } from '@/store/useTutorial';
    import FutureAspirationGuideModalExplanation from '@/components/canvas/future-aspirations/FutureAspirationGuideModalExplanation.vue';

    const router = useRouter();
    const { showTutorial, tutorialNavigateBack, onShowTutorial } = useTutorial();

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = useFutureAspirationsStore();

    await canvasStore.load();
    await store.load();
    // const guideStore = useGuideStore();

    onMounted(async () => {
        await activityStore.load();
        await activityStore.addEntryActivity(UserActivityArea.FutureAspiration);
    });

    const onDiscardChanges = () => {
        // This is done to reset the store, so changes are discarded, so next time the data is loaded from scratch
        // This is only done on stores that are not save on change, and have an explicit save button
        store.$reset();
    };

    const onSaveClick = async () => {
        await store.saveProgress();

        if (!store.isError) {
            await router.push(storyRoute);
        }
    };

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.FutureAspiration);
    });
</script>
