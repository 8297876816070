import { makeDate } from '@/lib/dates';
import { format } from 'date-fns';
import type { UserJob } from '@/api/types/userJob';

const DISPLAY_FORMAT = 'MMM yyyy';
export function formatJobDuration(job: UserJob): string {
    const fromDate = makeDate(job.from_date ?? null);
    const toDate = makeDate(job.to_date ?? null);
    const formattedFrom = fromDate ? format(fromDate, DISPLAY_FORMAT) : null;
    const formattedTo = toDate ? format(toDate, DISPLAY_FORMAT) : null;

    if (formattedFrom && formattedTo) {
        return `${formattedFrom} - ${formattedTo}`;
    } else if (fromDate) {
        return 'Current';
    } else {
        return '';
    }
}
